import React, { useState, useEffect } from "react";
import ClasswiseTable from "../tables/ClasswiseTable";
import { useResult } from "../../context/ResultContext";

export default function Modal({CwrModalOpen, closeModal}) {

    const { results } = useResult();

    const [selectedOption, setSelectedOption] = useState('');

    const [inputValue, setInputValue] = useState('');

    const [comValue, setComValue] = useState('');

    const closeCwrModal = () => {
        closeModal(false);
        setInputValue('');
        setSelectedOption('');
        setComValue();
    };  

    const printClicked = () => {
        alert('Sorry! Module is under development...');
    }
    // Event handler to update the input value
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    // Event handler to update the selected option
    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    useEffect(()=>{
        if(selectedOption !=='' && inputValue !==''){
            setComValue(selectedOption+'S'+inputValue);
        }
    },[selectedOption, inputValue]);

    const filteredStudent = results.filter(uid => uid.student_uid.split('-')[1] === `${comValue}`);
    // console.log(filteredStudent);

    const transformData = (data) => {
        const transformedData = [];
        const dataMap = new Map();
      
        data.forEach((item) => {
            
          const { student_uid, student, subject, ac_year, am_id, score, subject_code, te } = item;
          const key = `${student_uid}_${ac_year}`;
      
          if (dataMap.has(key)) {
            const existingData = dataMap.get(key);
            existingData.securedMarks.push({ am_id, te, subject, subject_code, score });
          } else {
            const newEntry = {
              student_uid,
              student,
              subject,
              ac_year,
              securedMarks: [{ am_id, te, subject_code, score }],
            };
            dataMap.set(key, newEntry);
          }
        });
      
        dataMap.forEach((value) => {
          transformedData.push(value);
        });
      
        return transformedData;
        
        }; 
          
    
    const [transData, setTransData] = useState([]);

    useEffect(() => {
        if(comValue !== ''){
            setTransData(transformData(filteredStudent));
        }
    }, [comValue]);

    return (
        <>
        {CwrModalOpen ? (
            <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none top-5"
            >
                <div className="relative w-auto my-6 mx-auto max-w-3xl top-80">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none top-80">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t top-80">
                    <h3 className="text-3xl font-semibold">
                        Student Results
                    </h3>
                    <button
                        className="p-1 ml-auto bg-transparent border-0 bg-slate-150 opacity-2 float-right text-xl leading-none hover:bg-red-300 rounded-full focus:outline"
                        onClick={closeCwrModal}
                    >
                        <span className="text-red-700 h-8 w-8 text-xl block outline-none focus:outline-none hover:text-2xl">
                        X
                        </span>
                    </button>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto overflow-scroll">
                    <div className="flex justify-center items-center p-4 w-full">  
                        <div className="mb-3 pt-0">
                            <select value={selectedOption} onChange={handleOptionChange} className="px-2 py-1 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full">
                                <option value="">Class</option>
                                <option value="C01">Class-I</option>
                                <option value="C02">Class-II</option>
                                <option value="C03">Class-III</option>
                                <option value="C04">Class-IV</option>
                                <option value="C05">Class-V</option>
                            </select>
                        </div>
                        <span className="p-2"></span>
                        <div className="mb-3 pt-0">
                            <input type="text" value={inputValue} onChange={handleInputChange} placeholder="Roll No" className="px-2 py-1 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"/>
                        </div>
                    </div>
                    <ClasswiseTable transData={transData} />
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                        className="bg-green-500 text-white active:bg-green-600 font-bold px-6 py-2 text-sm rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={closeCwrModal}
                    >
                        Close
                    </button>
                    <button
                        className="bg-blue-500 text-white active:bg-blue-600 font-bold text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={printClicked}
                    >
                        Print
                    </button>
                    </div>
                </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
        ) : null}
        </>
    );
}