import logo from './images/logo.png';
import React, { useState } from 'react'
import ClasswiseResult from './components/modals/ClasswiseResult';
import SuidResult from './components/modals/SuidResult';

function App() {

  const currentTime = new Date();
  const ac_year = currentTime.getFullYear();

  const [suidModalOpen, setSuidModalOpen] = useState(false);
  const [CwrModalOpen, setCwrModalOpen] = useState(false);

  const openSuidModal = () => {
    setSuidModalOpen(true);
  };

   const openCwrModal = () => {
    setCwrModalOpen(true);
  };

  return (
    <>
      <nav id="nav" className="bg-gray-200 shadow" role="navigation">
          <div className="container mx-auto p-4 flex justify-center flex-wrap items-center md:flex-no-wrap">
            <div className="block items-center">
              <a href="https://www.mglps.in/" rel="home">
                <img src={logo} alt="New Bithung Logo" width={140}/>
              </a>
            </div>
          </div>
          <div className="flex justify-center items-center">
            <h1 className='text-2xl text-blue-950 font-bold sm:text-center'>Maikhuli Govt. L.P. School</h1>
          </div>
          <div className="flex justify-center items-center sm:text-center">
            <h1 className='text-blue-950 mb-4'>Maikhuli, Dist.Ri-Bhoi, Meghalaya - 793101</h1>
          </div>
      </nav>
      <div className="container mx-auto p-4 flex justify-center flex-wrap items-center md:flex-no-wrap">
        <h2 className="text-3xl text-blue-950 font-bold mb-2">Online Result {ac_year}</h2>
      </div>
      <div className="flex justify-center items-center p-4">
        <button 
          className="rounded font-bold text-gray-50 bg-blue-500 px-4 py-2 shadow-2xl hover:bg-violet-600 active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300"
          onClick={openCwrModal}
          >
          Class wise
        </button> 
        <span className='p-2'></span>
        <button 
          className="rounded font-bold text-gray-50 bg-blue-500 px-3 py-2 shadow-2xl hover:bg-violet-600 active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300"
          onClick={openSuidModal}
          >
          Student UID
        </button>
      </div>
      <SuidResult  
        suidModalOpen={suidModalOpen}
        closeModal={setSuidModalOpen} 
      />
      <ClasswiseResult  
        CwrModalOpen={CwrModalOpen}
        closeModal={setCwrModalOpen} 
      />
    </>
  );
}

export default App;
