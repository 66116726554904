import React, { useState, useEffect } from "react";
import SuidTable from "../tables/SuidTable";
import { useResult } from "../../context/ResultContext";

export default function Modal({ suidModalOpen, closeModal }) {

  const { results } = useResult();

    const [inputValue, setInputValue] = useState('');

    const closeSuidModal = () => {
      closeModal(false);
      setInputValue('');
    };  

    // Event handler to update the input value
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };
    
    const filteredStudent = results.filter(uid => uid.student_uid === `${inputValue}`);
   
    const transformData = (data) => {
        const transformedData = [];
        const dataMap = new Map();
      
        data.forEach((item) => {
            
          const { student_uid, student, subject, ac_year, am_id, score, subject_code, te } = item;
          const key = `${student_uid}_${ac_year}`;
      
          if (dataMap.has(key)) {
            const existingData = dataMap.get(key);
            existingData.securedMarks.push({ am_id, te, subject, subject_code, score });
          } else {
            const newEntry = {
              student_uid,
              student,
              subject,
              ac_year,
              securedMarks: [{ am_id, te, subject_code, score }],
            };
            dataMap.set(key, newEntry);
          }
        });
      
        dataMap.forEach((value) => {
          transformedData.push(value);
        });
      
        return transformedData;
        
        };          
    
    const [transData, setTransData] = useState([]);

    useEffect(() => {
        if(inputValue !==''){
            setTransData(transformData(filteredStudent));
        } else{
            setTransData([]);
        }
    },[inputValue]);

    const printClicked = () => {
      alert('Sorry! Module is under development...');
  }
   
  return (
    <>
      {suidModalOpen ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-auto overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none "
          >
            <div className="pt-80 relative w-auto my-6 mx-auto max-w-3xl top-80">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none top-80">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t top-80">
                  <h3 className="text-3xl font-semibold">
                    Student Results
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 bg-slate-150 opacity-2 float-right text-xl leading-none hover:bg-red-300 rounded-full focus:outline"
                    onClick={closeSuidModal}
                  >
                    <span className="text-red-700 h-8 w-8 text-xl block outline-none focus:outline-none hover:text-2xl">
                      X
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto overflow-y-scroll">
                <div className="flex justify-center items-center p-4 w-full">                
                    <input className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm max-w-sm" placeholder="Enter Student UID" type="text" name="search" value={inputValue} onChange={handleInputChange} />   
                </div>
                <SuidTable transData={transData} />
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="bg-green-500 text-white active:bg-green-600 font-bold px-6 py-2 text-sm rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={closeSuidModal}
                  >
                    Close
                  </button>
                  <button
                    className="bg-blue-500 text-white active:bg-blue-600 font-bold text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={printClicked}
                  >
                    Print
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}