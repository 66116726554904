import React from 'react'

const ClasswiseTable = ({ transData }) => {    

    return (
      <div className='bg-gray-100'>
        {transData.length > 0 
              ?(<table className="table table-bordered border-primary">
                  <thead className='bg-gray-50 border-b-2 border-gray'>
                    <tr>
                      <th className='p-3 text-sm font-semibold tracking-wide text-left'>Name</th>
                      <th className='p-3 text-sm font-semibold tracking-wide text-left'>UID</th>
                      <th className='p-3 text-sm font-semibold tracking-wide text-left'>Roll</th>
                      <th className='p-3 text-sm font-semibold tracking-wide text-left'>Year</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className='bg-white'>
                      <td className='p-3 text-sm text-gray-700 '>{transData[0].student}</td>
                      <td className='p-3 text-sm text-gray-700 '>{transData[0].student_uid}</td>
                      <td className='p-3 text-sm text-gray-700 '>{transData[0].student_uid.split('-')[1].slice(4,5)}</td>    
                      <td className='p-3 text-sm text-gray-700 '>{transData[0].ac_year}</td>
                    </tr>
                  </tbody>
              </table>)
              :'No Student'
          }
          {transData.length > 0
                ?(
                  //First Internal Exam
                  <table className='w-full'>
                    <caption className='caption-top'>
                      <h4>1st Quarter Exam</h4>
                    </caption>
                    <thead className='bg-gray-50 border-b-2 border-gray'>
                        <tr>
                          <th className='p-3 text-sm font-semibold tracking-wide text-left'>Code</th>
                          <th className='p-3 text-sm font-semibold tracking-wide text-left'>Subject</th>
                          <th className='p-3 text-sm font-semibold tracking-wide text-left'>FM</th>
                          <th className='p-3 text-sm font-semibold tracking-wide text-left'>PM</th>
                          <th className='p-3 text-sm font-semibold tracking-wide text-left'>Secured</th>
                        </tr>
                      </thead>
                      {transData.map((item, index) => (
                      <tbody key={index}>
                          {item.securedMarks.filter(assessment => assessment.te === 'First Internal').map((mark, markIndex) => (
                            <tr key={markIndex} className='bg-white'>                               
                                <td className='p-3 text-sm text-gray-700 '>
                                  {mark.subject_code}                                  
                                </td>
                                <td className='p-3 text-sm text-gray-700 '>
                                  ({mark.subject} {!mark.subject?'MIL/Alt.E':''})                                   
                                </td>
                                <td className='p-3 text-sm text-gray-700 '>
                                  50                                    
                                </td>
                                <td className='p-3 text-sm text-gray-700 '>
                                 15                                    
                                </td>
                                <td className='p-3 text-sm text-gray-700 '>
                                  {mark.score}                                   
                                </td>
                            </tr>
                          ))}
                      </tbody>
                      ))}
                    </table>)                    
                    :''
                }
                {/* for Half-Yearly   */}
                {transData.length > 0
                ?(
                  //Second Internal Exam
                  <table className='w-full'>
                    <caption className='caption-top'>
                      <h4>Half-Yearly Exam</h4>
                    </caption>
                    <thead className='bg-gray-50 border-b-2 border-gray'>
                        <tr>
                          <th className='p-3 text-sm font-semibold tracking-wide text-left'>Code</th>
                          <th className='p-3 text-sm font-semibold tracking-wide text-left'>Subject</th>
                          <th className='p-3 text-sm font-semibold tracking-wide text-left'>FM</th>
                          <th className='p-3 text-sm font-semibold tracking-wide text-left'>PM</th>
                          <th className='p-3 text-sm font-semibold tracking-wide text-left'>Secured</th>
                        </tr>
                      </thead>
                      {transData.map((item, index) => (
                      <tbody key={index}>
                          {item.securedMarks.filter(assessment => assessment.te === 'Half Yearly').map((mark, markIndex) => (
                            <tr key={markIndex} className='bg-white'>                               
                                <td className='p-3 text-sm text-gray-700 '>
                                  {mark.subject_code}                                  
                                </td>
                                <td className='p-3 text-sm text-gray-700 '>
                                  ({mark.subject} {!mark.subject?'MIL/Alt.E':''})                                   
                                </td>
                                <td className='p-3 text-sm text-gray-700 '>
                                  50                                    
                                </td>
                                <td className='p-3 text-sm text-gray-700 '>
                                 15                                    
                                </td>
                                <td className='p-3 text-sm text-gray-700 '>
                                  {mark.score}                                   
                                </td>
                            </tr>
                          ))}
                      </tbody>
                      ))}
                    </table>)                    
                    :''
                }
                {/* for Second Internal Exam   */}
                {transData.length > 0
                ?(
                  //Second Internal Exam
                  <table className='w-full'>
                    <caption className='caption-top'>
                      <h4>Second Quarter Exam</h4>
                    </caption>
                    <thead className='bg-gray-50 border-b-2 border-gray'>
                        <tr>
                          <th className='p-3 text-sm font-semibold tracking-wide text-left'>Code</th>
                          <th className='p-3 text-sm font-semibold tracking-wide text-left'>Subject</th>
                          <th className='p-3 text-sm font-semibold tracking-wide text-left'>FM</th>
                          <th className='p-3 text-sm font-semibold tracking-wide text-left'>PM</th>
                          <th className='p-3 text-sm font-semibold tracking-wide text-left'>Secured</th>
                        </tr>
                      </thead>
                      {transData.map((item, index) => (
                      <tbody key={index}>
                          {item.securedMarks.filter(assessment => assessment.te === 'Second Internal').map((mark, markIndex) => (
                            <tr key={markIndex} className='bg-white'>                               
                                <td className='p-3 text-sm text-gray-700 '>
                                  {mark.subject_code}                                  
                                </td>
                                <td className='p-3 text-sm text-gray-700 '>
                                  ({mark.subject} {!mark.subject?'MIL/Alt.E':''})                                   
                                </td>
                                <td className='p-3 text-sm text-gray-700 '>
                                  50                                    
                                </td>
                                <td className='p-3 text-sm text-gray-700 '>
                                 15                                    
                                </td>
                                <td className='p-3 text-sm text-gray-700 '>
                                  {mark.score}                                   
                                </td>
                            </tr>
                          ))}
                      </tbody>
                      ))}
                    </table>)                    
                    :''
                }
                {/* for Annual Exam   */}
                {transData.length > 0
                ?(
                  //Second Internal Exam
                  <table className='w-full'>
                    <caption className='caption-top'>
                      <h4>Annual Exam</h4>
                    </caption>
                    <thead className='bg-gray-50 border-b-2 border-gray'>
                        <tr>
                          <th className='p-3 text-sm font-semibold tracking-wide text-left'>Code</th>
                          <th className='p-3 text-sm font-semibold tracking-wide text-left'>Subject</th>
                          <th className='p-3 text-sm font-semibold tracking-wide text-left'>FM</th>
                          <th className='p-3 text-sm font-semibold tracking-wide text-left'>PM</th>
                          <th className='p-3 text-sm font-semibold tracking-wide text-left'>Secured</th>
                        </tr>
                      </thead>
                      {transData.map((item, index) => (
                      <tbody key={index}>
                          {item.securedMarks.filter(assessment => assessment.te === 'Annual').map((mark, markIndex) => (
                            <tr key={markIndex} className='bg-white'>                               
                                <td className='p-3 text-sm text-gray-700 '>
                                  {mark.subject_code}                                  
                                </td>
                                <td className='p-3 text-sm text-gray-700 '>
                                  ({mark.subject} {!mark.subject?'MIL/Alt.E':''})                                   
                                </td>
                                <td className='p-3 text-sm text-gray-700 '>
                                  50                                    
                                </td>
                                <td className='p-3 text-sm text-gray-700 '>
                                 15                                    
                                </td>
                                <td className='p-3 text-sm text-gray-700 '>
                                  {mark.score}                                   
                                </td>
                            </tr>
                          ))}
                      </tbody>
                      ))}
                    </table>)                    
                    :''
                }
      </div>
    )
  }
  

export default ClasswiseTable
