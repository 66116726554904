import React, { createContext, useContext, useEffect, useState } from 'react';

// Create a context
const ResultContext = createContext();

// Custom hook to access the context
export function useResult() {
  return useContext(ResultContext);
}

// Create a provider component
export function ResultProvider({ children }) {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  // Function to fetch and set results from the API
  const fetchResults = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://mglps.in/api/marks/getMarks.php'); // Replace with your API endpoint
      //const response = await fetch('http://localhost/epromaster_v1.0/api/marks/getMarks.php'); // Replace with your API endpoint
      const data = await response.json();
      setResults(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchResults();
  }, []); // Fetch data when the component is mounted

 
  const value = {
    results,
    loading,
  };

  return (
    <ResultContext.Provider value={value}>
      {children}
    </ResultContext.Provider>
  );
}

